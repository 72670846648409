import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AddNewButtonComponent } from '../../buttons/add-new-button/add-new-button.component';
import { CustomizeButtonComponent } from '../../buttons/customize-button/customize-button.component';
import { SortDropdownComponent } from '../../dropdowns/sort-dropdown/sort-dropdown.component';
import {
  TableColumn,
  TableColumnConfigurable,
  TableQuickSortItem,
} from '../../../../core/modals/table.modal';
import { SearchBoxComponent } from '../../search/search-box/search-box.component';
import { MoreDropdownComponent } from '../../dropdowns/more-dropdown/more-dropdown.component';
import { EditButtonComponent } from '../../buttons/edit-button/edit-button.component';
import { DeleteButtonComponent } from '../../buttons/delete-button/delete-button.component';
import { MoreItems } from '../../../models/more-dropdown.config';
import { ButtonComponent } from '../../buttons/button/button.component';
import { SideMenuStore } from '../../../../core/store/side-menu.store';

@Component({
  selector: 'app-list-page-header',
  standalone: true,
  imports: [
    FormsModule,
    TranslateModule,
    AddNewButtonComponent,
    CustomizeButtonComponent,
    SortDropdownComponent,
    SearchBoxComponent,
    MoreDropdownComponent,
    EditButtonComponent,
    DeleteButtonComponent,
    ButtonComponent,
  ],
  templateUrl: './list-page-header.component.html',
  styleUrl: './list-page-header.component.scss',
})
export class ListPageHeaderComponent {
  @Input() isSearchEnabled: boolean = true;
  @Input() isColumsConfigurable: boolean = false;
  @Input() isQuickSortEnabled: boolean = false;
  @Input() isAddNewEnabled: boolean = false;
  @Input() isMoreButtonEnabled: boolean = false; //TODO: no need
  @Input() isEditButtonEnabled: boolean = false;
  @Input() isDeleteButtonEnabled: boolean = false;
  @Input() isSubmitButtonEnabled: boolean = false;
  @Input() isCustomButtonEnabled: boolean = false;

  @Input() customButtonType: string = '';
  @Input() customButtonLabel: string = '';
  @Input() customButtonLoading: boolean = false;
  @Input() customButtonLoadingLabel: string = '';

  @Input() title: string = '';
  @Input() addButtonLabel: string = '';
  @Input() editButtonLabel: string = '';
  @Input() deleteButtonLabel: string = '';
  @Input() quickSortItems: TableQuickSortItem[];
  @Input() coloums: TableColumn[] | TableColumnConfigurable[];
  @Input() moreItems: MoreItems[] = [];

  @Input() module: string = '';
  @Input() subModule: string = '';

  @Output() searchRequest = new EventEmitter<string>();
  @Output() addRequest = new EventEmitter<any>();
  @Output() editRequest = new EventEmitter<any>();
  @Output() deleteRequest = new EventEmitter<any>();
  @Output() clickRequest = new EventEmitter<any>();
  @Output() toggleRequest = new EventEmitter<any>();
  @Output() submitRequest = new EventEmitter<any>();

  @Output() moreItemTemplateRequest = new EventEmitter<any>();
  @Output() moreItemImportRequest = new EventEmitter<any>();
  @Output() moreItemExportRequest = new EventEmitter<any>();
  @Output() moreItemArchiveRequest = new EventEmitter<any>();
  @Output() moreItemDeleteRequest = new EventEmitter<any>();
  @Output() moreItemRefreshRequest = new EventEmitter<void>();

  constructor(public sideMenuStore: SideMenuStore) {}

  requestSearch(keyword: string) {
    this.searchRequest.emit(keyword);
  }

  requestAdd() {
    this.addRequest.emit();
  }

  requestEdit() {
    this.editRequest.emit();
  }

  requestDelete() {
    this.deleteRequest.emit();
  }

  requestSubmit() {
    this.submitRequest.emit();
  }
  requestClick() {
    this.clickRequest.emit();
  }

  requestToggleColoumn(field: string) {
    this.toggleRequest.emit(field);
  }

  requestMoreItemTemplate() {
    this.moreItemTemplateRequest.emit();
  }

  requestMoreItemImport() {
    this.moreItemImportRequest.emit();
  }

  requestMoreItemExport() {
    this.moreItemExportRequest.emit();
  }

  requestMoreItemArchive() {
    this.moreItemArchiveRequest.emit();
  }

  requestMoreItemDelete() {
    this.moreItemDeleteRequest.emit();
  }

  requestMoreItemRefresh() {
    this.moreItemRefreshRequest.emit();
  }
}
